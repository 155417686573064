import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {AddToCartApp} from './AddToCartApp';
import {bootstrapApp} from '../../commons/bootstrap';
import {ADD_TO_CART_DSN} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';

export const component = withSentryErrorBoundary(bootstrapApp(AddToCartApp, withStyles), {
  dsn: ADD_TO_CART_DSN,
  config: {environment: 'Native Component'},
});
